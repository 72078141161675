import {createMuiTheme} from "@material-ui/core";

export const lightTheme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            "@global": {
                html: {
                    height: "100%",
                },
                body: {
                    height: "100%",
                },
                "#___gatsby": {
                    height: "100%",
                },
                "#gatsby-focus-wrapper": {
                    height: "100%",
                },
            },
        },
    },
    palette: {
        background: {
            default: "#fce7d5",
            paper: "#424242",
        },
        text: {
            primary: "#262626",
        },
        primary: {
            main: "#2d2d2d",
        },
        secondary: {
            main: "#88FFBB",
        },
    },
    typography: {
        fontFamily: [
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
})
