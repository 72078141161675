/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import {
  createMuiTheme,
  ThemeProvider,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  makeStyles,
  Hidden,
  IconButton,
  Link,
  SwipeableDrawer, Drawer, Box, List, ListItem, ListItemText,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { useState } from "react"
import {lightTheme} from "../themes/lightTheme";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

const Layout: React.FC = ({ children }) => {
  const classes = useStyles()

  const pageValue =
    typeof window !== "undefined" &&
    window.location.pathname.substring(1, window.location.pathname.length)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      {/*<AppBar position="fixed" color="transparent" elevation={0}>*/}
      {/*  <Toolbar>*/}
      {/*    <Typography variant="h5" className={classes.title}>*/}
      {/*      <Link href="/" variant="inherit">*/}
      {/*        {data.site.siteMetadata.title}*/}
      {/*      </Link>*/}
      {/*    </Typography>*/}
      {/*    <Hidden xsDown>*/}
      {/*      <Tabs value={pageValue} aria-label="simple tabs example">*/}
      {/*        /!*<Tab label="About" href="/about" value="about"/>*!/*/}
      {/*      </Tabs>*/}
      {/*    </Hidden>*/}
      {/*    <Hidden smUp>*/}
      {/*      <IconButton onClick={toggleDrawer}>*/}
      {/*        <MenuIcon />*/}
      {/*      </IconButton>*/}
      {/*      <Drawer*/}
      {/*          open={drawerOpen}*/}
      {/*          anchor='right'*/}
      {/*          onClose={toggleDrawer}*/}
      {/*      >*/}
      {/*        <Box>*/}
      {/*          <List>*/}
      {/*                <ListItem button component={Link} href='/contact'>*/}
      {/*                  <ListItemText primary='Contact' />*/}
      {/*                </ListItem>*/}
      {/*          </List>*/}
      {/*        </Box>*/}
      {/*      </Drawer>*/}
      {/*    </Hidden>*/}
      {/*  </Toolbar>*/}
      {/*</AppBar>*/}
      {children}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
